<template>
  <div class="bac">
    <div class="main">
      <div class="top">
        <div class="top_left">
          <div class="tx"></div>
          <div class="top_info">
            <p>企业1234</p>
            <p>丰富的项目经验/荣誉能提升您的权威性,</p>
            <a>马上完善资质信息></a>
            <button>编辑信息</button>
            <button>修改密码</button>
          </div>
        </div>
        <div class="top_right">
          <div class="phone">
            <i style="font-size:28px" class="fa">&#xf10b</i>
            13011112222
          </div>
          <div class="mail">
            <i style="font-size:24px" class="fa">&#xf0e0</i>
            绑定邮箱
          </div>
          <div class="wechat">
            <i style="font-size:24px;color: #ec414d" class="fa">&#xf1d7</i>
            已绑定
          </div>
        </div>
      </div>
      <div class="medium">
        <div class="medium_head">
          <i></i>
          <p>基本信息</p>
        </div>
        <div class="medium_content">
          <div class="content_left">
            <div class="nicheng">
              <i>昵称：</i><p>企业1234</p>
            </div>
            <div class="city">
              <i>城市：</i><p>淄博</p>
            </div>
            <div class="address">
              <i>详细定制：</i><p>山东省淄博市</p>
            </div>
            <div class="introduce">
              <i>个人简介：</i><p>这列介绍不了我</p>
            </div>
            <div class="company_name">
              <i>公司名称：</i><p></p>
            </div>

          </div>
          <div class="content_right">
            <div class="name">
              <i>姓名：</i>
              <p>好名字</p>
            </div>
            <div class="sex">
              <i>性别：</i><p>男</p>
            </div>
            <div class="birthday">
              <i>生日：</i><p></p>
            </div>
            <div class="zhiye">
              <i>职业：</i><p></p>
            </div>
            <div class="start">
              <i>开始工作时间：</i><p></p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="medium_head">
          <i></i>
          <p>专业信息</p>
        </div>
        <div class="bottom_content">
          <div class="content_left">
            <div class="nicheng">
              <i>公司：</i><p>工业设计</p>
            </div>
            <div class="city">
              <i>职位</i><p>暂无</p>
            </div>
            <div class="address">
              <i>业务领域：</i><p></p>
            </div>

          </div>
          <div class="content_right">
            <div class="name">
              <i>研究方向：</i>
              <p></p>
            </div>
            </div>
          </div>
      </div>
      </div>
    </div>

</template>

<script>
export default {

  name: "CompanyInformation"

}
</script>

<style scoped>
.bac{
  width: 1920px;
  padding-left: 298px;
  background-color: #f5f5f5;
}

.main{
  float: left;
  width: 1458px;
  height: 915px;
  margin-left: 66px;
  margin-top: 60px;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.main .top{
  margin-left: 44px;
  padding-top: 28px;
}

.main .top .top_left{
  float: left;
  width: 756px;
  height: 188px;
  margin-right: 52px;
}

.main .tx{
  float: left;
  width: 174px;
  height: 174px;
  border-radius: 50%;
  /*background-color: pink*/
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.main .top_info{
  float: left;
  width: 564px;
  height: 164px;
  font-size: 20px;
  margin-left: 18px;
  margin-top: 22px;

}

.main .top_left p:nth-of-type(1){
  width: 86px;
  height: 20px;
  /*float: left;*/
  font-size: 20px;
  color: #212121;
  margin-bottom: 40px;
}

.main .top_left p:nth-of-type(2){
  float: left;
  height: 20px;
  font-size: 20px;
  color: #5c5c5e;
  margin-bottom: 35px;
}

.main .top .top_left a{
  display: block;
  float: left;
  font-size: 20px;
  color: #0379db;
  margin-bottom: 35px;
  /*margin-bottom: 35px;*/

}

.main .top .top_left button{
  width: 121px;
  height: 48px;
  color:#ffffff;
  font-size: 22px;
  border-radius: 5px;
}

.main .top .top_left button:nth-of-type(1){
  background-color: #363636;
  margin-right: 35px;
}

.main .top .top_left button:nth-of-type(2){
  background-color: #ec414d;
}

.top .top_right{
  padding-top: 13px;
}

.top .top_right div{
  height: 38px;
  line-height: 38px;
  color: #212121;
  font-size: 16px;
  margin-bottom: 28px;
  padding-top: 5px;
}

.top .top_right i{
  margin-right: 18px;
  margin-bottom: -5px;
}

.medium_head{
  width: 1458px;
  height: 50px;
  margin-left: 47px;
  margin-bottom: 28px;

}

.medium_head i{
  display: block;
  float: left;
  width: 8px;
  height: 48px;
  margin-right: 27px;
  background-color: #ec414d;
}

.medium_head p{
  float: left;
  width: 102px;
  margin-top: 10px;
  font-size: 24px;
  color: #5c5c5c;
}

.medium .medium_content{
  position: relative;
  left: 50px;
  width: 1332px;
  height: 300px;
  border: 1px solid #212121;
  border-radius: 5px;
  padding-top: 36px;
  margin-bottom: 24px;
  color: #212121;
}

.bottom .bottom_content{
  position: relative;
  left: 50px;
  width: 1332px;
  height: 185px;
  border: 1px solid #212121;
  border-radius: 5px;
  padding-top: 26px;
  margin-bottom: 24px;
  color: #212121;

}

.content_left{
  position: absolute;
  left: 108px;
  width: 660px;
  /* height: 230px; */
}

.content_left div{
  width: 100%;
  height: 24px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: left;
}

.content_left p{
  float: left;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
}

.content_left i{
  display: block;
  float: left;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
}

.content_right{
  position: absolute;
  width: 562px;
  /* height: 235px; */
  left: 770px;
}


.content_right div{
  width: 100%;
  height: 24px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: left;
}

.content_right p{
  float: left;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
}

.content_right i{
  display: block;
  float: left;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
}


</style>
